<template>
  <main class="index">
    <div class="h2">Обновлены недавно</div>
    <div class="slider-wrapper">
      <div :style="`width:${updates.length * 180}px;`" class="slider">
        <nuxt-link v-for="item in updates" :key="`slider-item-${item.id}`" :to="`/ranobe/${postUrl(item.id, item.title)}`" tag="div" class="item">
          <div class="poster">
            <img :data-src="item.poster" :alt="item.title" class="lazyload">
          </div>
          <strong>{{ item.title }}</strong>
        </nuxt-link>
      </div>
    </div>

    <h1 style="text-align: center;">
      Читайте ранобэ{{
        genre ? ' жанра ' + genre : ''
      }}{{
        year ? ' (' + year + ')' : ''
      }} онлайн бесплатно на русском
    </h1>

    <LastComments :comments="comments" />

    <div class="content">
      <div v-if="!$device.isDesktop" class="filters">
        <div class="row">
          <strong @click="showSub($event)">
            {{ genre ? genre : 'Все жанры'}}
          </strong>
          <div class="sub">
            <div @click="genre = ''">Все</div>
            <div v-for="g in genres" :key="`filters-genre-${g}`" @click="genre = g">
              {{ g }}
            </div>
          </div>
        </div>
      </div>
      <div class="comics">
        <Items :items="items" />
        <div class="more" />

        <div class="description">
          <p>
            Ранобэ (ранобэй) – это японские легкие романы, которые пользуются огромной популярностью в Японии и за ее пределами. Это литературный жанр, который объединяет в себе элементы фэнтези, научной фантастики, приключений, романтики и других жанров. Ранобэ часто иллюстрируются, что делает их более привлекательными для молодежи.
          </p>

          <p>
            Сам термин «ранобэ» происходит от японского слова «ranobe», что означает «легкий роман». Эти книги обычно имеют небольшой объем, от 50 до 500 страниц, и написаны простым языком, что делает их доступными для чтения даже для тех, кто не очень хорошо знает японский язык.
          </p>

          <p>
            Среди популярных примеров ранобэ можно выделить следующие:
          </p>

          <p>
            1. «Сворачивание времени» (Re:Zero kara Hajimeru Isekai Seikatsu) – это история о юноше по имени Субару, который попадает в параллельный мир и обнаруживает, что может вернуться во времени после смерти. Он пытается использовать свои способности, чтобы спасти жизнь своей возлюбленной Эмилии и других персонажей.
          </p>

          <p>
            2. «Мечта о неограниченной власти» (Mondaiji-tachi ga Isekai Kara Kuru Sou Desu yo?) – это история о трех подростках, которые попадают в мир фэнтези, где они участвуют в различных играх и сражениях, чтобы заработать очки и получить неограниченную власть.
          </p>

          <p>
            3. «Сильнейший в мире маг» (Mahouka Koukou no Rettousei) – это история о Тацуе Шиба, который является одним из самых сильных магов в мире. Он и его сестра Мияки учатся в магической школе, где они сталкиваются с различными препятствиями и опасностями.
          </p>

          <p>
            4. «Атака на титанов» (Shingeki no Kyojin) – это история о мире, где люди живут в городах, окруженных высокими стенами, чтобы защитить себя от титанов – огромных существ, которые питаются людьми. Главный герой Эрен Йегер решает стать частью армии, чтобы защитить свой город и узнать больше о титанах.
          </p>

          <p>
            5. «Слабый герой, но мой друг» (Tate no Yuusha no Nariagari) – это история о Наофуми Иватани, который попадает в мир фэнтези в качестве героя, но становится жертвой обмана и предательства. Он решает стать сильнее и защитить своих друзей, используя свои уникальные способности.
          </p>

          <p>
            Ранобэ – это не только легкие романы, но и источник вдохновения для аниме, манги и видеоигр. Многие из этих произведений стали популярными не только в Японии, но и во всем мире.
          </p>
        </div>
      </div>
      <div v-if="$device.isDesktop" class="filters">
        <div class="row">
          <strong @click="showSub($event)">
            {{ genre ? genre : 'Все жанры'}}
          </strong>
          <div class="sub">
            <div @click="genre = ''">Все</div>
            <div v-for="g in genres" :key="`filters-genre-${g}`" @click="genre = g">
              {{ g }}
            </div>
          </div>
        </div>

        <div v-if="$device.isDesktop" id="yandex_rtb_R-A-2270065-5" class="rsya-vertical"></div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  components: {
    Items: () => import('~/components/Items.vue'),
    LastComments: () => import('~/components/LastComments.vue'),
  },
  head() {
    const title = `Читайте ранобэ ${
      this.genre ? ' жанра ' + this.genre : ''
    }${
      this.year ? ' (' + this.year + ')' : ''
    } онлайн бесплатно на русском`

    const fullPath = `https://runobe.ru${
      this.genre || this.year ? '?' : ''
    }${
      this.year ? `year=${encodeURI(this.year)}` : ''
    }`

    return {
      title: title,
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: fullPath
        }
      ],
      meta: [
        {
          hid: 'keywords',
          name: 'keywords',
          content: `ранобэ онлайн читать бесплатно на русском`
        },
        {
          hid: 'description',
          name: 'description',
          content: `На нашем сайте вы можете бесплатно читать онлайн ранобэ на русском и с хорошим качеством изображения.`
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: fullPath
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: title
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: `На нашем сайте вы можете бесплатно читать онлайн ранобэ на русском и с хорошим качеством изображения.`
        },
      ]
    }
  },
  watch: {
    type() {
      this.getData()
    },
    genre() {
      this.getData()
    }
  },
  watchQuery: ['type', 'genre', 'year', 'author', 'translation'],
  async asyncData({ $axios, query }) {
    const type = query.type ? query.type : ''
    const genre = query.genre ? query.genre : ''
    const year = query.year ? query.year : ''
    const author  = query.author  ? query.author  : ''
    const translation = query.translation ? query.translation : ''
    let url = `/api/index?page=0`
    if (type) url += `&type=${encodeURIComponent(type)}`
    if (genre) url += `&genre=${encodeURIComponent(genre)}`
    if (year) url += `&year=${encodeURIComponent(year)}`
    if (author) url += `&author=${encodeURIComponent(author)}`
    if (translation) url += `&translation=${encodeURIComponent(translation)}`
    const { data } = await $axios.get(url)
    return {
      ...data,
      page: 1,
      type,
      genre,
      year,
      author,
      translation,
      genres: [
        'боевые искусства', 'боевик', 'вампиры', 'гарем', 'гендерная интрига', 'героическое фэнтези', 'додзинси',
        'дзёсэй', 'драма', 'детектив', 'игра', 'история', 'киберпанк', 'комедия', 'мистика',
        'меха', 'махо-сёдзё', 'научная фантастика', 'перерождение', 'повседневность', 'приключения',
        'психология', 'романтика', 'самурайский боевик', 'сборник', 'сверхъестественное', 'сёдзё',
        'сёдзё для взрослых', 'сёдзё-ай', 'сёнэн', 'сёнэн-ай', 'спокон', 'сэйнэн', 'спорт', 'трагедия',
        'триллер', 'ужасы', 'фантастика', 'фэнтези', 'школа', 'эротика', 'этти', 'юри', 'яой'
      ],
    }
  },
  mounted() {
    if (this.$device.isDesktop) window.addEventListener('scroll', this.stickyFilters)
    window.addEventListener('scroll', this.showMore)

    const slider = document.querySelector('.slider-wrapper')
    if (slider) slider.addEventListener('scroll', this.lazyLoadImgs)

  //   if (this.$device.isDesktop) {
  //     setTimeout(() => {
  //       const rsyaVertical = document.createElement('script')
  //       rsyaVertical.innerHTML = `
  // window.yaContextCb.push(()=>{
  // Ya.Context.AdvManager.render({
  //   renderTo: 'yandex_rtb_R-A-2270065-5',
  //   blockId: 'R-A-2270065-5'
  // })
  // })`
  //       document.body.appendChild(rsyaVertical)
  //     }, 200)
  //   }
  },
  beforeDestroy() {
    const slider = document.querySelector('.slider-wrapper')
    if (slider) slider.removeEventListener('scroll', this.lazyLoadImgs)

    if (this.$device.isDesktop) window.removeEventListener('scroll', this.stickyFilters)
    window.removeEventListener('scroll', this.showMore)
  },
  methods: {
    getData() {
      // if (this.loading) return
      // this.loading = true
      const type = this.type ? this.type : ''
      const genre = this.genre ? this.genre : ''
      const year = this.year ? this.year : ''
      const author  = this.author ? this.author  : ''
      const translation = this.translation ? this.translation : ''
      let url = `/?page=0` // `/api/comics?page=0`
      if (type) url += `&type=${encodeURIComponent(type)}`
      if (genre) url += `&genre=${encodeURIComponent(genre)}`
      if (year) url += `&year=${encodeURIComponent(year)}`
      if (author) url += `&author=${encodeURIComponent(author)}`
      if (translation) url += `&translation=${encodeURIComponent(translation)}`
      this.$router.push(url)
      // const { data } = await this.$axios.get(url)
      // this.items = data.items
      // this.loading = false
      // window.scrollTo(0, 0)
      // this.$nextTick(() => {
      //   this.lazyLoadImgs()
      // })
    },
    async showMore() {
      if (this.loading) return
      const elem = document.querySelector('.more')
      if (!elem) return
      const doc = document.documentElement.clientHeight
      const visible = doc - elem.getBoundingClientRect().top
      if (visible > -(doc / 2)) {
        this.loading = true
        const type = this.type ? this.type : ''
        const genre = this.genre ? this.genre : ''
        const year = this.year ? this.year : ''
        const author  = this.author  ? this.author  : ''
        const translation = this.translation ? this.translation : ''
        let url = `/api/comics?page=${++this.page}`
        if (type) url += `&type=${encodeURIComponent(type)}`
        if (genre) url += `&genre=${encodeURIComponent(genre)}`
        if (year) url += `&year=${encodeURIComponent(year)}`
        if (author) url += `&author=${encodeURIComponent(author)}`
        if (translation) url += `&translation=${encodeURIComponent(translation)}`
        const { data } = await this.$axios.get(url)
        const items = this.items.concat(data.items)
        this.items = items
        this.loading = false
      }
    },
    stickyFilters() {
      const elem = document.querySelector('.filters')
      if (!elem) return

      const leftPos = elem.getBoundingClientRect().left

      if (window.scrollY > 670) {
        elem.style.position = 'fixed'
        elem.style.top = '70px'
        elem.style.left = leftPos + 'px'
      } else {
        elem.style.position = 'relative'
        elem.style.top = '0px'
        elem.style.left = '0px'
      }
    },
    showSub(e) {
      const el = e.target.nextElementSibling
      if (el.style.display === 'block') {
        el.style.display = 'none'
      } else {
        el.style.display = 'block'
      }
    }
  }
}
</script>

<style src="~/assets/index.styl" lang="stylus" />
